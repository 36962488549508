<template>
    <div>
       
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {

    name: "Video",
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
                that.spin = true;
                that.getCampaigns()
                    .then(() => {
                        let campaign = that.campaigns.find(
                            (campaign) => campaign.campaignPrototype == 'video'
                        );
                        console.log("campaign: ", campaign);
                        if(!(campaign && campaign.campaignId)) throw new Error("Error Campaign");
                        that.campaignId = campaign.campaignId;
                        that.videoStart();
                    })
                    .catch((err) => {
                        console.log("err: ", err);
                        next("/error");
                    });
            } else {
                next("/error");
            }
        });
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            campaignId: '',
            videoInfo: {},
            spin: false
            
        };
    },
    created() {
        
    },
    computed: {
        ...mapState(["user"])
    },
    mounted() {
    },
    methods: {
        getCampaigns() {
            return (
                this.getCampaignAPI()
                    // this.campaignDummy()
                    .then((res) => {
                        this.campaigns = res.data.campaigns;
                        console.log("campaigns: ", this.campaigns);
                        return res;
                    })
            );
        },
        getCampaignAPI() {
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/${this.user.userId}?includeNonJoined=true`,
                method: "GET"
            };
            return this.$http(config);
        },
        callVideoInfoAPI() {
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/campaigns/${this.campaignId}/run?uuid=${this.user.userId}`,
                method: "GET"
            }
            return this.$http(config);
        },
        dummyVideoInfo() {
            let result = {
                "entryUrl": "https://portal-staging.tycard.app/third-modules/videos?settingId=3"
            };
            let promise = new Promise((resolve) => {
                resolve({data: result});
            });
            return promise;
        },
        videoStart() {
            this.callVideoInfoAPI()
            // this.dummyVideoInfo()
            .then(res => {
                this.videoInfo = JSON.parse(JSON.stringify(res.data));
                console.log("videoInfo: ", this.videoInfo);
                this.spin = false;
                window.location.replace(this.videoInfo.entryUrl);
            })
            .catch(err => {
                console.log("err: ", err);
                this.spin = false;
                this.$router.push('/error');
            });
        },
        
    }
};
</script>